@import '../../utils.scss';

.btn-start-processing {
  margin-right: 1rem;
}

.success-info {
  @extend .padded;
  margin-top: 1rem;
  background: lighten($brandLight, 15%);
}

.rating-additional-field {
  display: flex;
}