@import './utils.scss';

body {
  .btn {
    @include font-size(1.6);
    text-transform: uppercase;
    border: 1px solid $black !important;
    background: $almostBlack;
    color: $whitey !important;
    &:hover {
      border: 1px solid $brand;
    }
    &:active {
      background: $brand;
    }
  }

  .btn-success {
    background: $brand !important;
    border: darken($brand, 10%) !important;
    &:hover {
      color: $white !important;
      background: darken($brand, 20%) !important;
    }
    &:active {
      background: darken($brand, 10%) !important;
      color: $white !important;
    }
    &:focus {
      background: $brand !important;
      color: $white !important;
    }
  }

  .btn-delete {
    color: $white;
    background: $notice !important;
    border: darken($notice, 10%) !important;
    &:hover {
      color: $white !important;
      background: darken($notice, 20%) !important;
    }
    &:active {
      background: darken($notice, 10%) !important;
      color: $white !important;
    }
    &:focus {
      background: $notice !important;
      color: $white !important;
    }
  }

  .btn-danger {
    border: 1px solid darken($notice, 10%) !important;
  }

  .btn-reverse {
    text-transform: uppercase;
    border: 2px solid $white !important;
    background: $almostBlack;
    color: $white !important;
    &:hover {
      background: $black !important;
      color: $white !important;
    }
    &:active {
      background: $brand !important;
      color: $white !important;
    }
    &:focus {
      background: $almostBlack;
      color: $white !important;
    }
  }

}