@import '../utils';

.admin-index {
  max-width: 1200px;
  .admin-item {
    box-sizing: border-box;
    padding: 0.5rem;
    margin-top: auto;
    a {
      @extend .big-shadows;
      @include font-size(2.4);
      padding: 3rem 2rem;
      min-height: 100%;
      font-weight: 300;
      display: block;
      border: 1px solid $lightGray;
      border-radius: 0.25rem;
      margin-bottom: 1rem;
      span {
        margin-right: 1rem;
      }
      &:hover:after {
        content: " >"
      }
    }
  }
}
