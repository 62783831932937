@import '../../utils.scss';

.attachment-container {
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(transparent,rgba(0,0,0,.05) 40%,rgba(0,0,0,.1));
  padding: 1rem;
  width: 100%;
  position: relative;
  text-align: center;
  &.currently-focused-attachment {
    background: $brandAlt;
    color: $whitey;
    .attachment-name {
      @extend .attachment-details;
    }
  }
  .attachment-links {
    z-index: 10000;
    p, h3 {
      margin: 0;
      line-height: 1.3;
    }
    h3 {
      text-transform: uppercase;
    }
    p {
      white-space: wrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    @include font-size(1.2);
  }
  .attachment-name {
    display: none;
    color: $black;
  }
  &:hover, &:active {
    transition: 0.1s all;
    .attachment-name {
      @extend .attachment-details;
    }
  }
  img {
    box-shadow: 0;
    display: block;
  }
  h3 {
    padding-top: 1rem;
    z-index: 100000;
    font-style: italic;
  }
  &:active {
    background: darken($whitey, 10%);
  }
  .no-preview {
    background: $whitey;
    height: 17rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
