// V A R I A B L E S
$white: #fff;
$whitey: #F4F3F7;
$lightGray: #eee;
$separator: #C1BFC4;
$darkGray: #9A989D;
$almostBlack: #54515A;
$black: #3C3942;

$brand: #473178;
$brandAlt: $almostBlack;
$brandLight: #AF9DD1;

$admin-header: #f1ac4e;
$notice: #FF6300;

$font: "Fira Sans", serif;

$headerHeight: 4rem;
$headerPadding: 1rem;
$headerTotalHeight: $headerHeight + (2 * $headerPadding);

// M I X I N S
@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

// U T I L I T Y    C L A S S E S
.indent {
  background: linear-gradient(to bottom, rgba(229,229,229,1) 0%,rgba(240,240,240,1) 100%);
}

.padded {
  padding: 1rem;
}

.shadows {
  box-shadow: 0 0.2rem 0.5rem rgba(0,0,0,0.22);
}

.big-shadows {
  box-shadow: 0 0.1rem 2.5rem rgba(0,0,0,0.15);
}

.big-shadows-indent {
  box-shadow: 0 0.1rem 2.5rem rgba(0,0,0,0.15);
}

.text-shadows {
  text-shadow: 1px 1px 3px $white;
}

.attachment-details {
  background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0.99) 1%,rgba(255,255,255,0.01) 99%,rgba(255,255,255,0) 100%);
  transition: 0.1s all;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  height: 10rem;
  width: 100%;
  text-transform: uppercase;
  bottom: 2em;
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  justify-content: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
