@import '../utils.scss';

.scrollable-content {
  height: calc(100vh - #{$headerTotalHeight});
}

.Application {
  .currently-active-filter {
    @include font-size(1.5);
    line-height: 2.25;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-style: italic;
    width: 100%;
    display: inline-block;
  }
  height: 100vh;
  .next-and-previous {
    min-width: 23vw;
    max-width: 23vw;
    .btn {
      width: 50%;
    }
  }
  .application-content {
    @extend .scrollable-content;
    display: flex;
    .all-attachments {
      @extend .big-shadows-indent;
      @extend .scrollable-content;
      @include font-size(1.5);
      overflow-y: scroll;
      background: $whitey;
      color: $almostBlack;
      flex: 2;
      font-weight: 400;
      min-width: 20rem;
      max-width: 20rem;
      .attachment-count {
        @extend .padded;
        @extend .big-shadows-indent;
        @extend .indent;
        top: $headerTotalHeight;
        z-index: 1;
        width: 100%;
        color: $black;
        background: $white;
        text-align: center;
        font-style: italic;
      }
    }
    .current-attachment {
      @extend .scrollable-content;
      background: $whitey;
      color: $almostBlack;
      overflow-y: scroll;
      flex: 9;
      position: relative;
      &.zoom-on {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        z-index: 100;
        .current-attachment-zoom {
          right: 2rem;
        }
      }
    }
    .sidebar {
      @extend .big-shadows;
      @extend .scrollable-content;
      overflow: scroll;
      flex: 4;
      letter-spacing: 0.0125em;
      max-width: 25vw;
      min-width: 25vw;
    }
  }
}
