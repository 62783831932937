@import '../utils.scss';

.Error {
  height: 100vh;
  .Error-content {
    @extend .padded;
    h1 {
      @include font-size(3.1415);
      margin: 2rem 0 1rem 0;
    }
  }
}
