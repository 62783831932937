@import '../../utils.scss';

.metadata-container {
  h3 {
    @include font-size(4);
    font-weight: 700;
    padding: 2rem 2rem 1rem 2rem;
  }
  .metadata {
    line-height: 1.3;
    padding: 1rem 2rem;
    h2 {
      font-weight: 700;
    }
  }
}
