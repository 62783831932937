@import '../utils.scss';

.logo {
  max-width: 100%;
  display: block;
  height: $headerHeight;
  @media(max-width: 768px) {
    max-width: 20vw;
    padding-right: 1rem;
  }
}

.loading-indicator {
  width: 100%;
  min-height: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.application-header {
  @extend .big-shadows-indent;
  background-image: linear-gradient(transparent,rgba(0,0,0,.05) 40%,rgba(0,0,0,.1));
  padding: $headerPadding;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: $black;
  color: $white;
  a {
    &:hover {
      text-decoration: underline;
      background: $black;
      color: $white;
    }
  }
  label {
    color: $separator;
  }
}

.admin-header {
  background: $admin-header;
  color: $black;
  a {
    &:hover {
      background: $admin-header;
    }
  }
  .admin-panel-title {
    font-weight: 400;
    letter-spacing: 0.0125em;
  }
}
