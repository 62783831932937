@import '../../utils.scss';

.zoom {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(255,255,255, 0.95);
}

.zoom-button {
  text-shadow: 0 0 1rem $white;
  opacity: 0.4;
  position: fixed;
  z-index: 1;
  border: 0;
  padding: 2rem;
  margin-right: 0;
  margin-top: 0;
  background: 0;
  color: $black;
  outline: none;
  transition: 0.1s all;
  border-radius: 50%;
  img {
    background: rgba(255,255,255,0.5);
    width: 5rem;
    height: 5rem;
    padding: 0.6rem;
    border: 1px solid black;
    box-shadow: 0 0.3rem 1rem rgba(255,255,255,0.22);
  }
  &:hover {
    opacity: 1;
  }
  &:active {
    background: transparentize(darken($whitey, 10%), 0.5);
  }
}

.zoom {
  .attachment-container {
    background: $almostBlack;
  }
}

.attachment-container {
  padding: 1rem;
  .attachment-page-number {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem auto;
    max-width: 80%;
    left: 0;
    padding: 1rem;
    height: 5rem;
    text-shadow: 0 1px 1px $white;
    font-weight: 400;
    line-height: 1.4;
    color: $black;
    @include font-size(1.4);
    background: $whitey;
    opacity: 0.2;
    transition: 0.2s all;
    background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,0.24) 54%,rgba(255,255,255,0) 71%,rgba(255,255,255,0) 100%);
    background-position: 0 -10px;
    background-repeat: no-repeat;
    &:hover {
      opacity: 1;
    }
    &.zoom-on {
      @include font-size(2.4);
      text-shadow: 0 2px 3px $white;
      opacity: 1;
    }
  }
  img {
    width: 100%;
    display: block;
  }
}
