.react-bs-table th {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.0125em;
  .order {
    zoom: 1.2;
    .dropdown span, .dropup span {
      color: #3C3942 !important;
    }
  }
}

.react-bs-table-tool-bar {
  .row {
    display: flex;
    flex-direction: row-reverse;
    max-width: 100%;
    .col-sm-6 {
      flex: 2;
      &:last-child {
        flex: 1;
      }
    }
  }
}

.react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter option[value=''], .react-bs-table .react-bs-container-header > table > thead > tr > th .select-filter.placeholder-selected, .react-bs-table .react-bs-container-header > table > thead > tr > th .filter::-webkit-input-placeholder, .react-bs-table .react-bs-container-header > table > thead > tr > th .number-filter-input::-webkit-input-placeholder {
  color: #3C3942;
}
