@import './utils.scss';

html {
  font-size: 62.5%; // Resets font sizes so that 1rem = 10px, so we can use paddings ang margins based in rem.
}

body {
  @include font-size(1.7);
  font-family: $font;
  margin: 0;
  padding: 0;
  font-weight: 300;
}

p {
  margin-bottom: 1rem;
  line-height: 1.488;
}

pre {
  font-family: monospace;
  white-space: pre;
  padding: 0.5rem;
  margin: 1rem 0;
}

a, .link-button {
  border: 0;
  background: none;
  color: #000;
  text-decoration: none;
  color: inherit;
  padding: 0;
  cursor: pointer;
  &:active {
    color: #000;
  }
  &:hover {
    color: #000;
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
    color: #000;
  }
}

img {
  width: 100%;
}

strong {
  font-weight: 700;
}

@mixin placeholder {
  &.placeholder { @content }
  &:-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
}

input {
  @include placeholder {
    color: $darkGray !important;
  }
}

fieldset {
  background: rgba(0,0,0,0.05);
  padding: 1rem;
  border-radius: 4px;
}