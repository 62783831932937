@import '../utils.scss';

.Application-list {

  .help {
    color: $separator;
    cursor: pointer;
    &:hover {
      color: $white;
    }
  }

  .grantField .select-filter {
    zoom: 0.8;
    padding: 2px 3px;
    line-height: 1.2;
    height: 20px;
  }

  .additional-ratings-available {
    padding-left: 0.5em;
    font-size: 12px;
    color: $darkGray;
    text-transform: uppercase;
    vertical-align: text-top;
  }

  .btn-reviewable {
    min-width: 15em;
  }

  .application-header .btn-not-reviewable {
    border: 2px solid #03CF00 !important;
  }

  .application-header .btn-is-reviewable {
    border: 2px solid #FFCA00 !important;
  }

  .bundle-alternatives-count {
    @extend .ellipsis;
  }

  .bundle-alternatives-container {
    display: flex;
    * {
      display: flex;
      align-items: center;
      label {
        margin-right: 0.5rem;
      }
      .bundle-alternatives {
        border: 0 !important;
        margin-left: 1rem;
        width: auto;
        @media(max-width: 768px) {
          max-width: 25vw;
        }
      }
    }
  }

  .rodal-modal {
    text-align: center;
    h2 {
      @include font-size(3);
      padding: 1rem 0;
    }
    strong {
      font-weight: 400;
    }
    a {
      text-decoration: underline;
    }
  }

  .table-container {
    padding: 0.5rem;
    .btn-download-excel {
      float: right;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }
    .react-bs-table-bordered {
      @extend .big-shadows;
      border: 0;
      border-radius: 0;
    }
    table {
      .star-rating-list {
        font-size: 70%;
      }
      width: 100%;
      table-layout: fixed;
      text-align: left;
      th, td {
        border: 0;
        border-left: 1px solid $whitey;
        padding: 1rem;
      }
      tbody {
        tr {
          cursor: pointer;
          border-bottom: 1px solid transparent;
          &:hover {
            border-bottom: 1px solid $separator;
          }
        }
      }
    }
  }
  footer {
    @extend .indent;
    text-align: center;
    font-style: italic;
    margin: 0.5rem;
    p {
      @extend .padded;
    }
  }
}
