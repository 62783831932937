@import '../../utils.scss';

.review-tools {
  padding: 2rem 2rem;
  margin-top: 1rem;
  border-top: 1px solid $whitey;
  h2 {
    font-weight: 700;
  }
  .all-ratings {
    .rating-helper {
      @include font-size(1.4);
      text-transform: uppercase;
      font-weight: 400;
      color: $darkGray;
      .rating-subject {
        color: $almostBlack;
        font-weight: 700;
      }
    }
    .rating-container {
      @media screen and (max-width: 1068px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include font-size(1.5);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
      .star-rating {
        @include font-size(2.0);
        margin-right: 0.5rem;
        &.smaller {
          @include font-size(1.6);
        }
        border: 1px solid $separator;
        border-radius: 4px;
        padding: 0.5rem;
        background: $whitey;
        &:hover {
          border-color: $brandLight;
        }
        &:active {
          background: $white;
        }
      }
    }
  }
  .comment-textarea {
    width: 100%;
    height: 10rem;
    max-height: 30rem;
    border-radius: 4px;
    max-width: 100%;
    min-width: 100%;
    min-height: 5rem;
    padding: 0.5rem;
    border: 1px solid $separator;
    margin-top: 0.5rem;
    background: $whitey;
    box-shadow: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: block;
    &:focus {
      border-color: $brandLight;
      background: $white;
    }
    &:hover {
      border-color: $brandLight;
    }
    &:active {
      background: $white;
    }
  }
  .add-comment {
    margin-top: 0.5rem;
  }
  .no-comments {
    font-style: italic;
  }
  .comments-list {
    margin: 2rem 0;
    .comment {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      font-size: 88%;
      background: $whitey;
      margin-bottom: 0.5rem;
      border-left: 3px double $separator;
      .comment-created {
        font-weight: 700;
        font-size: 88%;
        padding-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        letter-spacing: 0.025em;
        color: $almostBlack;
      }
    }
  }
}

.review-tools-disabled {
  position: relative;
  margin-top: 1rem;
  border-top: 1px solid $whitey;
  display: flex;
  justify-content: center;
  align-items: center;
  .disabled-text {
    @extend .text-shadows;
    font-weight: 400;
    position: absolute;
    padding: 0 3rem;
    text-align: center;
    line-height: 1.8;
    font-style: italic;
  }
}
