@import '../utils';

.Admin-page {
  .page-content {
    @extend .padded;
    .page-title {
      @include font-size(4);
      font-weight: 700;
      margin: 1rem 0 2rem 0;
    }
  }
  h3 {
    @include font-size(2.3);
    margin: 1rem 0 0.5rem 0;
    line-height: 1.5;
  }
  textarea {
    min-height: 20rem;
    max-width: 100%;
  }
}
